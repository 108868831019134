import { MemberChallenge } from '@wix/ambassador-challenges-v1-challenge/types';
import { ControllerFlowAPI } from '@wix/yoshi-flow-editor';

export function renderSeoMetatagsList(
  flowAPI: ControllerFlowAPI,
  programs: MemberChallenge[],
  chVisible: Record<string, { hidden: boolean }>,
) {
  const { isViewer } = flowAPI.environment;

  if (!isViewer) {
    return;
  }

  const { wixCodeApi } = flowAPI.controllerConfig;
  const seoAPI = wixCodeApi.seo;
  const { baseUrl } = wixCodeApi.location;

  // https://docs.google.com/document/d/1oJm2Jcg1CEzUNu2nflUCbw8I3BPBLvKtUEwUxuAggMs/edit#heading=h.gjdgxs
  // upd: https://wix.slack.com/archives/C01HMHGN26S/p1689847908934289
  const itemData = {
    settings: programs.map((program, index) => {
      const programUrl = `${baseUrl || ''}/challenge-page/${
        program.challenge.settings.seo.slug
      }`;

      return {
        position: index + 1,
        isHidden: chVisible[program.challenge.id.substr(0, 5)]?.hidden ?? false,
        url: programUrl,
        id: program.challenge.id,
      };
    }),
    challenges: programs,
  };

  seoAPI.renderSEOTags({
    itemType: 'PROGRAMS_COMPONENT',
    itemData,
  });
}
